import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@components/shared/Icon/Icon';

function getYearsData(dateOfBirth = false) {
  const years = [];
  const currentYear = new Date().getFullYear();
  let minYear = currentYear - (dateOfBirth ? 125 : 0);
  let maxYear = currentYear + (dateOfBirth ? 0 : 15);

  while (maxYear >= minYear) {
    years.push(maxYear);
    maxYear--;
  }

  return years;
}

const YearPicker = ({ children, selected, onSelect, dateOfBirth = false }) => {
  const selectedYearParsed = parseInt(selected, 10);
  const currentYear = new Date().getFullYear();
  const yearsData = getYearsData(dateOfBirth);
  const [showYearSelect, setShowYearSelect] = useState(false);

  const toggleYearSelect = () => {
    setShowYearSelect(!showYearSelect);
  };

  const handleButtonClick = (event) => {
    const year = parseInt(event.target.dataset.year, 10);
    onSelect(year);
    toggleYearSelect();
  };

  return (
    <div className="year-picker">
      <button className="year-picker__dropdown" type="button" onClick={toggleYearSelect}>
        {children}
        <Icon className="year-picker__dropdown-icon" size={24} icon="drop-down" />
      </button>
      {showYearSelect && (
        <div className="year-picker__menu">
          <div className="year-picker__list">
            {yearsData.map((year) => (
              <button
                className={cn('year-picker__item', {
                  'year-picker__item--current': year === currentYear,
                  'year-picker__item--selected': selectedYearParsed === year
                })}
                data-year={year}
                type="button"
                onClick={handleButtonClick}
                key={year}
              >
                {year}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

YearPicker.propTypes = {
  children: PropTypes.any,
  selected: PropTypes.any,
  onSelect: PropTypes.func
};

export default YearPicker;
