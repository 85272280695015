import React from 'react';
import classnames from 'classnames';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import selectStyles from '@components/shared/Form/Select/styles';
import {
  CustomDropDownIndicator,
  CustomMultiValueRemove,
  CustomValueContainer
} from '@components/shared/Form/Select/CustomComponents';
import FormError from '@components/shared/Form/Error/FormError';

const Select = ({ className, onChange, name, components, error, indicator = false, ...props }) => {
  const handleChange = (data) => {
    let value = data;

    if (!value && props.isMulti) {
      value = [];
    }

    onChange({
      name: name,
      value: value
    });
  };

  let Component = ReactSelect;

  if (props.isCreatable) {
    Component = CreatableSelect;
  }

  if (props.isAsync) {
    Component = AsyncSelect;
  }

  if (props.isAsync && props.isCreatable) {
    Component = AsyncCreatableSelect;
  }

  return (
    <div
      className={classnames('form-select', {
        'form-select--error': error
      })}
    >
      <Component
        {...props}
        components={{
          ValueContainer: CustomValueContainer,
          IndicatorSeparator: () => null,
          MultiValueRemove: CustomMultiValueRemove,
          DropdownIndicator: indicator ? CustomDropDownIndicator : null,
          ClearIndicator: null,
          ...components
        }}
        placeholder={props.label || props.placeholder}
        styles={selectStyles}
        className={className}
        classNamePrefix="select"
        onChange={handleChange}
      />
      <FormError error={error} />
    </div>
  );
};

Select.propTypes = ReactSelect.propTypes;

export default Select;
